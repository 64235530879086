.copy-clipboard{
    color: #212121;
    padding: 2px 5px;
    margin-bottom: 3px;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -5px;
}

button.no-border{
    padding: 0;
    margin: 0;
    margin-right: 7px;
    border: none;
}