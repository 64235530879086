* {
    box-sizing: border-box;
}

.container {
    width: 90%;
    margin-top: 2%;
}

.header {
    background-color: #fefefe;
    border-bottom: 1px solid #fafafa;

}

.menu-hamburger {
    display: inline-block;
    vertical-align: middle;
    width: 10%;
}

.logo {
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
    width: 70%;
    color: #2e2e2e;
}

.social {
    display: inline-block;
    width: 15%;
}

.social a {
    margin: 0 5px;
    color: #212121;
}

.header-content {
    width: 90%;
    margin: 0 auto;
    /* padding-top: 15px; */
    padding: 10px 5px;
}

.custom-menu {
    padding: 1em;
    width: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    vertical-align: middle;
    padding-right: 30px;
}

.custom-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.custom-menu ul ul {
    padding-left: 1em;
}

.custom-menu li {
    margin: 0;
}

.custom-menu li a {
    display: inline-block;
    color: #212121;
    text-decoration: none;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: .05rem;
    font-weight: 600;
    display: inline-block;
    position: relative;
}

.custom-menu ul ul li a {
    font-weight: 400;
    color: #2d2d2d;
}

.custom-menu li a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #212121;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.custom-menu li a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.custom-menu li.visible>a {
    color: #212121;
    transform: translate(5px);
}

.title-header {
    text-transform: uppercase;
    font-size: 1.5rem;
    letter-spacing: .1rem;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
}

.description-issue{
    font-size: 1.3rem;
    font-weight: 400;
    margin-bottom: 10px;
}

.title-issue {
    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: .1rem;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
}

.title-issue::after {
    content: '';
    position: absolute;
    width: 3%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #212121;
    transform: scaleX(1);
    transform-origin: bottom left;
}

.title-header::after {
    content: '';
    position: absolute;
    width: 3%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #212121;
    transform: scaleX(1);
    transform-origin: bottom left;
}



.title-custom {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .1rem;
    font-weight: 600;
}

.center-v {
    display: flex;
    margin-top: 10px;
    align-items: center;
    vertical-align: middle;
}

.custom-panel {
    background: #EEE;
    border-radius: 3px;
    padding: 5px 10px;
    border: 1px solid #f2f2f2;
    margin-bottom: 3px;
    font-size: 13px;
    position: relative;
}

.bordered-right {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-5 {
    margin-top: 5px;
}

.textAreaInput {
    background: none;
    border: none;
    height: 250px;
    width: 100%;
}

.tooltip {
    --_t-bkg: rgba(0, 0, 0, 0.8);
    --_t-radius: 0.5em;
    --_t-px: 0.5rem;
    --_t-py: 0.5rem;
    --_t-w: 8rem;
    --_t-arrow-w: 12px;
    position: relative;
    isolation: isolate;
    cursor: pointer;
}

.tooltip:not(.btn) {
    border-bottom: 1px dotted var(--color-accent);
}

.tooltip::before,
.tooltip::after {
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear;
}

.tooltip::before {
    content: attr(data-tooltip);
    padding: var(--_t-py) var(--_t-px);
    width: var(--_t-w);
    color: #fff;
    text-align: center;
    font-size: 80%;
    text-transform: none;
    background-color: var(--_t-bkg);
    border-radius: var(--_t-radius);
    transform: translate(-50%, calc(-100% + var(--_t-arrow-w) * -1));
}

.tooltip::after {
    content: "";
    width: var(--_t-arrow-w);
    aspect-ratio: 1;
    background-color: var(--_t-bkg);
    -webkit-clip-path: polygon(44% 100%, 0 44%, 100% 44%);
    clip-path: polygon(44% 100%, 0 44%, 100% 44%);
    transform: translate(-50%, calc(-100% + var(--_t-arrow-w) / 2 * -1));
}

.tooltip:hover::after,
.tooltip:hover::before {
    opacity: 1;
    visibility: visible;
}

.tooltip--right::before,
.tooltip--right::after {
    left: auto;
    right: 0;
    top: 50%;
}

.tooltip--right::before {
    transform: translate(calc(100% + var(--_t-arrow-w)), -50%);
}

.tooltip--right::after {
    -webkit-clip-path: polygon(50% 100%, 0 50%, 50% 0);
    clip-path: polygon(50% 100%, 0 50%, 50% 0);
    transform: translate(calc(100% + var(--_t-arrow-w) / 2), -50%);
}

.tooltip--bottom::before,
.tooltip--bottom::after {
    top: auto;
    bottom: 0;
}

.tooltip--bottom::before {
    transform: translate(-50%, calc(100% + var(--_t-arrow-w)));
}

.tooltip--bottom::after {
    -webkit-clip-path: polygon(100% 54%, 0 54%, 54% 0);
    clip-path: polygon(100% 54%, 0 54%, 54% 0);
    transform: translate(-50%, calc(100% + var(--_t-arrow-w) / 2));
}

.tooltip--left::before,
.tooltip--left::after {
    top: 50%;
    left: 0;
}

.tooltip--left::before {
    transform: translate(calc(-100% + var(--_t-arrow-w) * -1), -50%);
}

.tooltip--left::after {
    -webkit-clip-path: polygon(100% 50%, 50% 0, 50% 100%);
    clip-path: polygon(100% 50%, 50% 0, 50% 100%);
    transform: translate(calc(-100% + var(--_t-arrow-w) / 2 * -1), -50%);
}

.img100-vs-extension {
    width: auto;
    max-width: 100%;
    border-radius: 3px 3px 0 0;
    object-fit: fill;
    max-height: 100px;
}

.vs-extension {
    border-bottom: 1px solid #f1f1f1;
    background-color: #fafafa;
    border-radius: 3px;
    margin-top: 10px;
}

.image-vs-extension {
    width: 100%;
    max-height: 100px;
    min-height: 100px;
    display: inline-block;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.title-vs-extension {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 5px;
    text-transform: uppercase;
    color: #3A3A3A;
}

.content-vs-extension {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;

}

.description-vs-extension {
    font-size: 13px;
}

.ulid-text-simple {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
}

.textAreaInput:focus {
    outline: none;
    border: none;
}

@media screen and (max-width: 768px) {
    .social {
        width: 30%;
    }

    .logo {
        width: 50%;
    }
}

.card-table__body__card {
    display: block;
    border-radius: 5px;
    font-size: 1.3em;
    display: inline-block;
    width: 100%;
    height: 95px;

}

.card-table__body__item{
    position: relative;
    width: 75px;
    height: 100px;
    margin: 20px 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 35px;
}

.card-table__body__card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100px;
    border: 1px solid #f1f1f1;
    color: #666;
    background: #fcfcfc;
    border-radius: 3px;
}

.card-table__body__card__content.voted {
    border: 1px solid #202020;
    color: #FFF;
    background: #484848
}

.card-table__body__card__footer {
    font-size: 12px;
    text-align: center;
    border-top: none;
    padding: 5px;
    margin-top: 0px;
}

.card-table__body__card__footer p {
    margin-top: 0px;
    vertical-align: top;

}

.card-table__body__card__me {
    display: block;
    flex: 0 0 50px;
    font-size: 1.1em;
    display: inline-block;
    width: 55px;
    height: 90px;
    margin: 5px 5px;
    cursor: pointer;
}

.card-table__body__card__content__me:hover {
    border: 1px solid #202020;
    color: #FFF;
    background: #484848;
}

.card-table__body__card__content__me {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    border: 1px solid #f1f1f1;
    color: #666;
    background: #fcfcfc;
    border-radius: 3px;
}

.custom-login-button{
    font-size: 11px !important;
    height: 30px !important;
    box-shadow: none !important;
    border: 1px solid #efefef !important;
    width: 200px !important;
    display: inline-block !important;
}


.custom-form-item {
  background-color: #e5e5e5;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  color: #5a5656;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  height: 50px;
  outline: none;
  padding: 0px 10px;
  width: 280px;
  -webkit-appearance: none;
}

input[type="submit"] {
  background-color: #008dde;
  border: none;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  color: #f4f4f4;
  cursor: pointer;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  height: 50px;
  text-transform: uppercase;
  width: 300px;
  -webkit-appearance: none;
}

.bordered-right{
    border-right: 1px solid #f1f1f1;
}

h1 { 
    font-size: 1.1em;
    margin-bottom: 10px;
}

.centered{
    text-align: center;
}

.prev-vote, .voting, .next-vote{
    display: inline-block;
    border: 1px solid #F2F2F2;
    border-radius: 2px;
    width: 33%;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
    color: #CCC;
}

.prev-vote{
    border-right: none;
    border-left: none;
    background-color: #FAFAFA;
    font-size: 11px;
}

.voting{
    height: 50px;
    line-height: 50px;
    color: #a2a2a2;
}

.next-vote{
    border-left: none;
    background-color: #FAFAFA;
    border-right: none;
    font-size: 11px;
}

.custom-p{
    margin-bottom: 0px;
}

.grid{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.row{
    display: block;
    margin: 0 -10px;
    width: 100%;
}

.cell{
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    border: 0.01em solid #F2F2F2;
    color: rgba(0,0,0,0);
}

.cell-filled{
    background-color: #212121;
}

.tetris-grid{
    border: 1px solid #F2F2F2;
}

.button-icons{
    font-size: 26px;
}