.footer{
    width: 100%;
    bottom: 0;
    background-color: #fff;
    margin-top: 30px;
    text-align: right;
    border-top: 1px solid #f2f2f2;
}

.footer-text{
    font-weight: 300;
    font-size: 12px;
    margin-top: 10px;
    margin-right: 50px;
    color: #3c3c3c;
}

.footer-icon{
    font-size: 20px;
    margin-bottom: -5px;
}