.knobs:before {
    content: attr(data-no);
    position: absolute;
    top: 4px;
    left: 4px;
    width: 22px;
    height: 8px;
    color: #212121;
    font-size: 8px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 6px 4px;
    background-color: #dad9d9;
    border-radius: 2px;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  }
  
.checkbox:active + .knobs:before {
    width: 46px;
  }
  
  .checkbox:checked:active + .knobs:before {
    margin-left: -26px;
  }
  
  .checkbox:checked + .knobs:before {
    content: attr(data-yes);
    left: 40px;
    color: #FFF;
    background-color: #212121;
  }
  
  .checkbox:checked ~ .layer {
    background-color: #EEE;
  }
  
  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 28px;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }
  
  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }
  
  .button.b2 {
    border-radius: 2px;
  }
  
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  .knobs {
    z-index: 2;
  }
  
  .layer {
    width: 100%;
    background-color: #eee;
    transition: 0.3s ease all;
    z-index: 1;
  }

  .no-border{
    padding: 0;
    margin: 0;
    margin-right: 7px;
    border: none;
}

.settings-title{
  display: inline-block;
  vertical-align: middle;
  margin-top: -7px;

}

.settings-check{
  display: inline-block;
  vertical-align: middle;

}