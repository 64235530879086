  .hamburger {
    display: none;
  }
  
  .line {
    width: 20px;
    height: 3px;
    background-color: #000;
    margin: 2px;
    transition: all 0.3s ease-in-out;
  }
  
  @media screen and (max-width: 768px) {
    
    .custom-menu {
      position: absolute;
      margin-top: -25px;
    }
  
    .hamburger {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: left;
      cursor: pointer;
    }
  
    .line {
      background-color: #212121;
    }
  
    .menu {
      display: none;
      flex-direction: column;
      position: absolute;
      z-index: 1;
      background: #FAFAFA;
      border: 1px solid #F2F2F2;
      width: 95%;
      border: 1px solid #EEE;
      border-bottom: 3px solid rgb(203, 203, 203);
      border-top: none;
    }

    .menu li{
        margin-left: 20px
    }

    .menu ul {
      display: block;
    }
  
    .menu.open {
      display: flex;
    }
  }
  